<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item
                :to="{ path: '/system_Management/system_management_home' }"
                >系统管理</el-breadcrumb-item
            >
            <el-breadcrumb-item>部门列表</el-breadcrumb-item>
        </el-breadcrumb>

        <el-card>
            <el-button type="primary" @click="addDeptDialogVisible = true"
                >添加部门</el-button
            >
            <!-- 一级部门 -->
            <el-row
                :class="['bottom_bd', i1 === 0 ? 'top_bd' : '', 'vcenter']"
                v-for="(item1, i1) in deptList"
                :key="item1.id"
            >
                <el-col :span="4">
                    <el-button
                        type="danger"
                        plain
                        size="small"
                        @click="clickItem(item1)"
                        >{{ item1.name }}</el-button
                    >
                    <i class="el-icon-caret-right"></i>
                </el-col>
                <!-- 二级部门 -->
                <el-col :span="20">
                    <el-row
                        :class="[i2 === 0 ? '' : 'top_bd', 'vcenter']"
                        v-for="(item2, i2) in item1.childs"
                        :key="item2.id"
                    >
                        <el-col :span="5">
                            <el-button
                                type="primary"
                                plain
                                size="small"
                                @click="clickItem(item2)"
                                >{{ item2.name }}</el-button
                            >
                            <i
                                class="el-icon-caret-right"
                                v-if="item2.childs"
                            ></i>
                        </el-col>
                        <!-- 三级部门 -->
                        <el-col :span="19">
                            <el-row
                                :class="[i3 === 0 ? '' : 'top_bd', 'vcenter']"
                                v-for="(item3, i3) in item2.childs"
                                :key="item3.id"
                            >
                                <el-col :span="6">
                                    <el-button
                                        type="success"
                                        plain
                                        size="small"
                                        @click="clickItem(item3)"
                                        >{{ item3.name }}</el-button
                                    >
                                    <i
                                        class="el-icon-caret-right"
                                        v-if="item3.childs"
                                    ></i>
                                </el-col>
                                <!-- 四级部门 -->
                                <el-col :span="18">
                                    <el-row
                                        :class="[
                                            i4 === 0 ? '' : 'top_bd',
                                            'vcenter',
                                        ]"
                                        v-for="(item4, i4) in item3.childs"
                                        :key="item4.id"
                                    >
                                        <el-col :span="7">
                                            <el-button
                                                type="warning"
                                                plain
                                                size="small"
                                                @click="clickItem(item4)"
                                                >{{ item4.name }}</el-button
                                            >
                                            <i
                                                class="el-icon-caret-right"
                                                v-if="item4.childs"
                                            ></i>
                                        </el-col>
                                        <!-- 五级部门 -->
                                        <el-col :span="17">
                                            <el-button
                                                type="info"
                                                plain
                                                size="small"
                                                v-for="item5 in item4.childs"
                                                :key="item5.id"
                                                @click="clickItem(item5)"
                                                >{{ item5.name }}</el-button
                                            >
                                        </el-col>
                                    </el-row>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
        </el-card>
        <!-- 添加部门Dialog -->
        <el-dialog
            title="添加部门"
            :visible.sync="addDeptDialogVisible"
            width="50%"
            @close="addDeptDialogClose"
        >
            <el-form
                ref="addDeptFormRef"
                :model="addDeptForm"
                :rules="addDeptFormRules"
                label-width="100px"
            >
                <el-form-item label="部门名称：" prop="name">
                    <el-input v-model="addDeptForm.name"></el-input>
                </el-form-item>
                <el-form-item label="上级部门：" prop="pid">
                    <el-cascader
                        style="width: 100%"
                        :options="deptList"
                        :props="{
                            checkStrictly: true,
                            label: 'name',
                            value: 'id',
                            children: 'childs',
                        }"
                        v-model="selectPid"
                        @change="selectPidChange"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="部门标示：" prop="wildcard">
                    <el-input v-model="addDeptForm.wildcard"></el-input>
                </el-form-item>
                <el-form-item label="部门描述：" prop="desc">
                    <el-input v-model="addDeptForm.desc"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDeptDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="addDept">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 部门详情Dialog -->
        <el-dialog
            title="权限详情"
            :visible.sync="deptDetailDialogVisible"
            width="50%"
        >
            <el-form
                ref="deptDetailFormRef"
                :model="deptDetailForm"
                :rules="deptDetailFormRules"
                label-width="100px"
            >
                <el-form-item label="权限名称：" prop="name">
                    <el-input v-model="deptDetailForm.name"></el-input>
                </el-form-item>
                <el-form-item label="部门标示：" prop="wildcard">
                    <el-input v-model="deptDetailForm.wildcard"></el-input>
                </el-form-item>
                <el-form-item label="部门描述：" prop="desc">
                    <el-input v-model="deptDetailForm.desc"></el-input>
                </el-form-item>
                <el-form-item label="赛区" prop="districtList">
                    <el-select
                        multiple
                        placeholder="请选择"
                        style="width: 100%"
                        v-model="deptDetailForm.districtList"
                        @change="$forceUpdate()"
                        clearable
                    >
                        <el-option
                            v-for="item in dict_district"
                            :key="item.id"
                            :label="item.districtName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button
                    type="danger"
                    @click="showDeletedeptTip(deptDetailForm.id)"
                    >删 除</el-button
                >
                <el-button @click="deptDetailDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="editDept">保 存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import { getSectionList, editDeptData, deleteSysUserinfo, queryAllDistrictPage, createDeptData, getDeptDistrict, saveEditDistrict } from '@/http/api'
export default {
  data () {
    return {
      addDeptDialogVisible: false,
      deptList: [],
      deptDistrictList: [],
      addDeptForm: {
      },
      addDeptFormRules: {
        name: [{ required: true, message: '请输入部门名称', trigger: 'blur' }],
        wildcard: [{ required: true, message: '请输入部门标示', trigger: 'blur' }],
        desc: [{ required: true, message: '请输入部门描述', trigger: 'blur' }]
      },
      selectPid: [],
      deptDetailDialogVisible: false,
      deptDetailForm: {},
      deptDetailFormRules: {
        name: [{ required: true, message: '请输入部门名称', trigger: 'blur' }],
        wildcard: [{ required: true, message: '请输入部门标示', trigger: 'blur' }],
        desc: [{ required: true, message: '请输入部门描述', trigger: 'blur' }]
      }
    }
  },
  created () {
    this.getDistrictList()
    this.getAllDept()
  },
  methods: {
    // 获取赛区筛选数据
    getDistrictList () {
      queryAllDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    getAllDept () {
      getSectionList(this.addAccountForm).then((res) => {
        this.deptList = res.data
      }).catch((err) => {
        console.log('err', err)
      })
    },
    addDeptDialogClose () {
      this.selectPid = []
      this.addDeptForm.pid = null
      this.$refs.addDeptFormRef.resetFields()
    },
    clickItem (deptData) {
      this.deptDetailForm = deptData
      this.getDeptDistrict(deptData)
    },
    // 获取部门下的赛区
    getDeptDistrict (deptData) {
      var formData = {
        id: deptData.id,
        pageNum: 1,
        pageSize: 100
      }
      getDeptDistrict(formData).then((res) => {
        this.deptDistrictList = res.data
        var districtList = []
        for (let index = 0; index < res.data.length; index++) {
          const element = res.data[index]
          districtList.push(element.id)
        }
        this.deptDetailForm.districtList = districtList
        this.deptDetailDialogVisible = true
      }).catch((err) => {
        console.log('err', err)
      })
    },
    selectPidChange () {
      if (this.selectPid.length > 3) {
        this.$message.warning('不能创建五级部门')
        this.selectPid = ''
      } else {
        this.addDeptForm.pid = this.selectPid[this.selectPid.length - 1]
      }
    },
    addDept () {
      console.log('this.addDeptForm', this.addDeptForm)
      this.$refs.addDeptFormRef.validate(async valid => {
        if (!valid) return
        createDeptData(this.addDeptForm).then((res) => {
          this.$message.success('添加部门成功')
          this.addDeptDialogVisible = false
          this.getAllDept()
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    showDeletedeptTip (deptId) {
      this.$confirm('此操作将永久删除该部门, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.deleteDeptById(deptId)
      }).catch(() => { })
    },
    deleteDeptById (deptId) {
      deleteSysUserinfo(deptId).then((res) => {
        this.$message.success('删除部门成功')
        this.deptDetailDialogVisible = false
        this.getAllDept()
      }).catch((err) => {
        console.log('err', err)
      })
    },
    // 保存部门信息
    editDept () {
      this.$refs.deptDetailFormRef.validate(async valid => {
        if (!valid) return
        editDeptData(this.deptDetailForm).then((res) => {
          this.saveDeptDistrict(this.deptDetailForm)
        }).catch((err) => {
          console.log('err', err)
        })
      })
    },
    // 保存部门下赛区
    saveDeptDistrict (deptDetailForm) {
      saveEditDistrict({ deptId: deptDetailForm.id, districts: deptDetailForm.districtList }).then((res) => {
        this.$message.success('保存部门成功')
        this.deptDetailDialogVisible = false
        this.getAllDept()
      }).catch((err) => {
        console.log('err', err)
      })
    }
  }
}
</script>

\<style lang="less" scoped>
.el-button {
    margin: 7px;
}

.top_bd {
    border-top: 1px solid #eee;
}

.bottom_bd {
    border-bottom: 1px solid #eee;
}

.vcenter {
    display: flex;
    align-items: center;
}
</style>
